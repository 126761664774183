const themes = {
  ScientificSearch: {
    primaryColor: '54, 108, 157',
    headerBg: `
      background: rgba(81,123,166,1);
      background: -moz-linear-gradient(left, rgba(81,123,166,1) 0%, rgba(101,157,207,1) 51%, rgba(7,58,106,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(81,123,166,1)), color-stop(51%, rgba(101,157,207,1)), color-stop(100%, rgba(7,58,106,1)));
      background: -webkit-linear-gradient(left, rgba(81,123,166,1) 0%, rgba(101,157,207,1) 51%, rgba(7,58,106,1) 100%);
      background: -o-linear-gradient(left, rgba(81,123,166,1) 0%, rgba(101,157,207,1) 51%, rgba(7,58,106,1) 100%);
      background: -ms-linear-gradient(left, rgba(81,123,166,1) 0%, rgba(101,157,207,1) 51%, rgba(7,58,106,1) 100%);
      background: linear-gradient(to right, rgba(81,123,166,1) 0%, rgba(101,157,207,1) 51%, rgba(7,58,106,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#517ba6', endColorstr='#073a6a', GradientType=1 );
    `,
    headerTitleColor: 'white',
    headerSubtitleColor: 'white',
    searchButtonColor: 'white',
    searchButtonColorHover: '54, 108, 157',
    searchButtonBgHover: 'white',
  },
  TlIc: {
    primaryColor: '34, 113, 116',
    headerBg: `
      background: rgba(17,102,105,1);
      background: -moz-linear-gradient(-45deg, rgba(17,102,105,1) 0%, rgba(103,173,176,1) 61%, rgba(119,186,189,1) 72%, rgba(67,134,137,1) 100%);
      background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(17,102,105,1)), color-stop(61%, rgba(103,173,176,1)), color-stop(72%, rgba(119,186,189,1)), color-stop(100%, rgba(67,134,137,1)));
      background: -webkit-linear-gradient(-45deg, rgba(17,102,105,1) 0%, rgba(103,173,176,1) 61%, rgba(119,186,189,1) 72%, rgba(67,134,137,1) 100%);
      background: -o-linear-gradient(-45deg, rgba(17,102,105,1) 0%, rgba(103,173,176,1) 61%, rgba(119,186,189,1) 72%, rgba(67,134,137,1) 100%);
      background: -ms-linear-gradient(-45deg, rgba(17,102,105,1) 0%, rgba(103,173,176,1) 61%, rgba(119,186,189,1) 72%, rgba(67,134,137,1) 100%);
      background: linear-gradient(135deg, rgba(17,102,105,1) 0%, rgba(103,173,176,1) 61%, rgba(119,186,189,1) 72%, rgba(67,134,137,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#116669', endColorstr='#438689', GradientType=1 );
    `,
    headerTitleColor: 'white',
    headerSubtitleColor: 'white',
    searchButtonColor: 'white',
    searchButtonColorHover: '34, 113, 116',
    searchButtonBgHover: 'white',
  },
  EconomPrav: {
    primaryColor: '103, 36, 29',
    headerBg: `
      background: #a34746; /* Old browsers */
      background: -moz-linear-gradient(-45deg,  #a34746 0%, #a34746 0%, #aa675a 0%, #914444 16%, #914444 48%, #a8675c 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg,  #a34746 0%,#a34746 0%,#aa675a 0%,#914444 16%,#914444 48%,#a8675c 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg,  #a34746 0%,#a34746 0%,#aa675a 0%,#914444 16%,#914444 48%,#a8675c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a34746', endColorstr='#a8675c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    `,
    headerTitleColor: 'white',
    headerSubtitleColor: 'white',
    searchButtonColor: 'white',
    searchButtonColorHover: '103, 36, 29',
    searchButtonBgHover: 'white',
  },
  ScientificNotes: {
    primaryColor: '76, 44, 101',
    headerBg: `
      background: #603487; /* Old browsers */
      background: -moz-linear-gradient(-45deg,  #603487 0%, #795893 51%, #816a93 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg,  #603487 0%,#795893 51%,#816a93 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg,  #603487 0%,#795893 51%,#816a93 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#603487', endColorstr='#816a93',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    `,
    headerTitleColor: 'white',
    headerSubtitleColor: 'white',
    searchButtonColor: 'white',
    searchButtonColorHover: '76, 44, 101',
    searchButtonBgHover: 'white',
  },
  Ipp: {
    primaryColor: '122, 70, 20',
    headerBg: `
      background: rgba(170,113,57,1);
      background: -moz-linear-gradient(-45deg, rgba(170,113,57,1) 0%, rgba(237,187,138,1) 100%);
      background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(170,113,57,1)), color-stop(100%, rgba(237,187,138,1)));
      background: -webkit-linear-gradient(-45deg, rgba(170,113,57,1) 0%, rgba(237,187,138,1) 100%);
      background: -o-linear-gradient(-45deg, rgba(170,113,57,1) 0%, rgba(237,187,138,1) 100%);
      background: -ms-linear-gradient(-45deg, rgba(170,113,57,1) 0%, rgba(237,187,138,1) 100%);
      background: linear-gradient(135deg, rgba(170,113,57,1) 0%, rgba(237,187,138,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7A4614', endColorstr='#edbb8a', GradientType=1 );
    `,
    headerTitleColor: 'white',
    headerSubtitleColor: 'white',
    searchButtonColor: 'white',
    searchButtonColorHover: '122, 70, 20',
    searchButtonBgHover: 'white',
  },
  Auditorium: {
    primaryColor: '24, 84, 130',
    headerBg: `
      background: rgba(36,119,187,1);
      background: -moz-linear-gradient(left, rgba(36,119,187,1) 0%, rgba(49,169,221,1) 50%, rgba(36,119,187,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(36,119,187,1)), color-stop(50%, rgba(49,169,221,1)), color-stop(100%, rgba(36,119,187,1)));
      background: -webkit-linear-gradient(left, rgba(36,119,187,1) 0%, rgba(49,169,221,1) 50%, rgba(36,119,187,1) 100%);
      background: -o-linear-gradient(left, rgba(36,119,187,1) 0%, rgba(49,169,221,1) 50%, rgba(36,119,187,1) 100%);
      background: -ms-linear-gradient(left, rgba(36,119,187,1) 0%, rgba(49,169,221,1) 50%, rgba(36,119,187,1) 100%);
      background: linear-gradient(to right, rgba(36,119,187,1) 0%, rgba(49,169,221,1) 50%, rgba(36,119,187,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2477bb', endColorstr='#2477bb', GradientType=1 );
    `,
    headerTitleColor: 'white',
    headerSubtitleColor: 'white',
    searchButtonColor: 'white',
    searchButtonColorHover: '24, 84, 130',
    searchButtonBgHover: 'white',
  },
};

export default (domain) => themes[domain];
