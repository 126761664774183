export default {
  ru: {
    translation: {
      header: {
        ScientificSearch: {
          title: 'НАУЧНЫЙ ПОИСК МОЛОДЫХ ИССЛЕДОВАТЕЛЕЙ',
          subtitle: 'Электронный научный журнал',
        },
        EconomPrav: {
          title: 'ЮРИСТ В СФЕРЕ ЭКОНОМИЧЕСКОГО ПРАВОСУДИЯ',
          subtitle: 'Электронный научный журнал',
        },
        ScientificNotes: {
          title: 'УЧЕНЫЕ ЗАПИСКИ',
          subtitle: 'Электронный научный журнал Курского государственного университета. Основан в 1941 году как печатное издание',
        },
        Ipp: {
          title: 'ИСТОРИКО-ПРАВОВЫЕ ПРОБЛЕМЫ. НОВЫЙ РАКУРС',
          subtitle: 'Электронный научный журнал. ISSN 2309-1592',
        },
        TlIc: {
          title: 'ТЕОРИЯ ЯЗЫКА И МЕЖКУЛЬТУРНАЯ КОММУНИКАЦИЯ',
          subtitle: 'Электронный научный журнал. ISSN 2219-8660',
        },
        Auditorium: {
          title: 'AUDITORIUM',
          subtitle: 'Электронный научный журнал. ISSN 2312-4180',
        },
      },
    },
  },
};
